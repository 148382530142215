<template>
  <div class="service-page">
    <van-nav-bar title="自助服务" left-arrow  @click-left="$router.go(-1)"/>
    <div class="banner">
      <img :src="imgs.banner" alt="">
    </div>
    <div>
      <section class="part-a">
        <a class="item item1" href="services/strategy">
          <img :src="imgs.ico1" alt="">
          <div class="intro">
            <h3>服务政策</h3>
            <p>公开透明，服务保障</p>
          </div>
        </a>
        <a class="item item2" href="services/repair" >
          <img :src="imgs.ico2" alt="">
          <div class="intro">
            <h3>在线报修</h3>
            <p>一键报修，省时省心</p>
          </div>
        </a>
        <!-- <a class="item item3" href="services/door" >
          <img :src="imgs.ico3" alt="">
          <div class="intro">
            <h3>跑步机上门服务</h3>
            <p>足不出户，尽享服务</p>
          </div>
        </a> -->
        <!-- <a class="item item4" href="services/care" >
          <img :src="imgs.ico4" alt="">
          <div class="intro">
            <h3>Amazfit care+</h3>
            <p>服务多元，分享心声</p>
          </div>
        </a> -->
      </section>
      <section class="part-b">
        <a class="item" href="services/query-method" >
          <img :src="imgs.ico5" alt="">
          <h3>维修查询</h3>
        </a>
        <a class="item" href="services/accessories">
          <img :src="imgs.ico7" alt="">
          <h3>配件价格</h3>
        </a>
        <!-- <a class="item" href="services/faq">
          <img :src="imgs.ico8" alt="">
          <h3>常见问题</h3>
        </a> -->
        <a class="item" href="services/download">
          <img :src="imgs.ico9" alt="">
          <h3>使用手册</h3>
        </a>
        <!-- 暂时关闭  https://jira.huami.com/browse/WEBSITE-340-->
        <!-- <a class="item" href="services/customer-service">
          <img :src="imgs.ico15" alt="">
          <h3>在线反馈</h3>
        </a> -->
      </section>
      <section class="part-c">
        <div class="wrap">
          <h3>Amazfit 产品停止技术支持公告</h3>
          <p><em>尊敬的用户您好：</em><br/>
            以下产品已停止销售，Amazfit 已停止对以下产品提供技术支持服务（含硬件维修和软件升级服务），但您仍可以在Amazfit公众号享受技术解答等服务，感谢您的理解与支持。</p>

            <p><em>停止技术支持产品清单如下：</em><br/>
            </p>
            <table>
              <thead>
                <tr>
                  <th>序号</th>
                  <th>设备型号</th>
                  <th>型号</th>
                  <th>停止服务起始时间</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Amazfit PowerBuds</td>
                  <td>A1965</td>
                  <td>2022年4月1日</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Amazfit ZenBuds</td>
                  <td>A1943</td>
                  <td>2022年10月1日</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Amazfit 龙之付手环</td>
                  <td>A1605</td>
                  <td>2023年4月1日</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Amazfit 月霜&赤道手环</td>
                  <td>A1501</td>
                  <td>2023年9月1日</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Amazfit  PowerBuds Pro</td>
                  <td>A2030</td>
                  <td>2023年11月1日</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Amazfit 米动健康手环</td>
                  <td>A1607</td>
                  <td>2024年5月1日</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Amazfit 米动手环</td>
                  <td>A1702</td>
                  <td>2024年5月1日</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Amazfit 米动健康手环 1S</td>
                  <td>A1710</td>
                  <td>2024年6月17日</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Amazfit 米动手环2</td>
                  <td>A1712</td>
                  <td>2024年8月2日</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Amazfit 快乐鸭儿童健康手表</td>
                  <td>A2149</td>
                  <td>2024年11月26日</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>Amazfit 智能运动手表</td>
                  <td>A1435</td>
                  <td>2024年12月11日</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>Amazfit 智能运动手表 2系列</td>
                  <td>A1609</td>
                  <td>2024年12月11日</td>
                </tr>
                <tr>
                  <td>13</td>
                  <td>Amazfit 智能运动手表 3系列</td>
                  <td>A1928</td>
                  <td>2024年12月11日</td>
                </tr>
                <tr>
                  <td>14</td>
                  <td>Amazfit 智能手表</td>
                  <td>A1801</td>
                  <td>2024年12月11日</td>
                </tr>
                <tr>
                  <td>15</td>
                  <td>Amazfit 智能手表青春版</td>
                  <td>A1808</td>
                  <td>2024年12月11日</td>
                </tr>
                <tr>
                  <td>16</td>
                  <td>Amazfit 智能手表 2系列</td>
                  <td>A1807</td>
                  <td>2024年12月11日</td>
                </tr>
                <tr>
                  <td>17</td>
                  <td>Amazfit 智能手表 2 ECG</td>
                  <td>A1903</td>
                  <td>2024年12月11日</td>
                </tr>
                <tr>
                  <td>18</td>
                  <td>Amazfit 米动健康手表</td>
                  <td>A1916</td>
                  <td>2024年12月11日</td>
                </tr>
                <tr>
                  <td>19</td>
                  <td>Amazfit 米动健康手环（黄山一号版）</td>
                  <td>A1930</td>
                  <td>2024年12月11日</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>Amazfit 米动手表青春版</td>
                  <td>A1608</td>
                  <td>2024年12月11日</td>
                </tr>
                <tr>
                  <td>21</td>
                  <td>Amazfit 米动手表青春版 Lite</td>
                  <td>A1915</td>
                  <td>2024年12月11日</td>
                </tr>
                <tr>
                  <td>22</td>
                  <td>Amazfit Neo</td>
                  <td>A2001</td>
                  <td>2024年12月11日</td>
                </tr>
                <tr>
                  <td>23</td>
                  <td>Amazfit X</td>
                  <td>A1941</td>
                  <td>2024年12月11日</td>
                </tr>
                <tr>
                  <td>24</td>
                  <td>Amazfit 米动手表青春版 1S</td>
                  <td>A1805</td>
                  <td>2025年1月9日</td>
                </tr>
              </tbody>
            </table>
            <p>
              <em>说明：</em>
              <br/>
                1、上述仅适用于在中国大陆地区销售的Amazfit终端产品。<br/>
                2、销售商向您作出的非Amazfit保证的其它承诺，Amazfit不作任何承诺。
            </p>
        </div>
        <p><br/></p>
        <div class="wrap">
          <h3>小贴士</h3>
          <p><em>请注意：</em><br/>
            具有心率检测功能的产品，其心率传感器发出的可见光能量非常微弱，目前无证据表明会对皮肤和人体造成任何损伤，请放心佩戴。</p>

            <p><em>佩戴时请留意：</em><br/>
            1、避开伤口未愈合部位；<br/>
            2、保持接触皮肤部位的干爽；<br/>
            3、定期清洁产品，避免汗渍等造成细菌滋生；<br/>
            4、如佩戴接触的部位出现红肿等症状时，请停止使用并咨询医生。</p>

            <p><em>日常保养清洁建议：</em><br/>
            1、防水产品建议定期使用清水清洗产品及腕带，并用软布擦干后再佩戴；<br/>
            2、清洗时不要使用肥皂、洗手液、浴液等清洁剂，以避免化学物质残留刺激皮肤或腐蚀设备；<br/>
            3、佩戴设备沐浴（支持沐浴的设备）、游泳（支持游泳的设备）、出汗后，请及时清洁并擦干设备；<br/>
            4、皮质(皮胶)表带不具备防水功能，切勿沾水以免缩短表带使用寿命；<br/>
            5、浅色的腕带需尽量避免接触深色衣物，以防被染色。</p>
        </div>
      </section>
      <section class="part-d">
        <h2>联系我们</h2>
        <div class="list">
          <!-- <div class="item">
            <img :src="imgs.ico10" alt="">
            <span>400-000-6666</span>
            <p>全国服务热线</p>
            <p>（周一至周日 8:00-22:00）</p>
          </div> -->
          <!-- <div class="item">
            <img :src="imgs.ico11" alt="">
            <span>在线客服</span>
            <p>请咨询各销售平台在线客服</p>
          </div> -->
          <div class="item" @click="showWeixin=true">
            <img :src="imgs.ico14" alt="">
            <span>Amazfit公众号</span>
            <p>扫一扫关注我们</p>
          </div>
          <div class="item" @click="goWeibo">
            <img :src="imgs.ico13" alt="">
            <span>Amazfit微博</span>
            <p>关注我们</p>
          </div>
        </div>
      </section>
      <van-overlay :show="showWeixin" @click="showWeixin = false">
        <div class="wrapper">
          <van-image :src="imgs.ico14"></van-image>
        </div>
      </van-overlay>

    </div>
  </div>
</template>

<script>
import { CDN_URL } from '../../config'

export default {
  name: 'service',
  data () {
    return {
      showWeixin: false,
      weixinQrcode: CDN_URL + '/images/service/wechat-qrcode.png',
      imgs: {
        banner: 'https://site-cdn.huami.com/files/amazfit/cn/banner/service-mb-banner.jpg',
        ico1: CDN_URL + '/images/service/service-ico1.png',
        ico2: CDN_URL + '/images/service/service-ico2.png',
        ico3: CDN_URL + '/images/service/service-ico3.png',
        ico4: CDN_URL + '/images/service/service-ico4.png',
        ico5: CDN_URL + '/images/service/service-ico5.png',
        ico6: CDN_URL + '/images/service/service-ico6.png',
        ico7: CDN_URL + '/images/service/service-ico7.png',
        ico8: CDN_URL + '/images/service/service-ico8.png',
        ico9: CDN_URL + '/images/service/service-ico9.png',
        ico10: CDN_URL + '/images/service/service-ico10.png',
        ico11: CDN_URL + '/images/service/service-ico11.png',
        ico12: CDN_URL + '/images/service/service-ico12.png',
        ico13: CDN_URL + '/images/service/service-ico13.png',
        ico14: 'https://img-cdn.zepp.com/webapp-create-platform/weixin/a38d4c6e16be62c8e8ed498376c10bfa.jpg',
        ico15: CDN_URL + '/images/service/service-ico15.png'

      }
    }
  },
  methods: {
    goWeibo () {
      location.href = 'https://m.weibo.cn/u/5326814390'
    }
  }
}
</script>

<style lang="scss">
.service-page {
  background-color: #F3F3F3;

  .contact {
    h2 {
      text-align: center;
    }

    .service-item {
      padding: 40px 5px 0;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .van-image {
      width: 80%;
    }
  }
  .part-c {
    table {
      width: 100%;
      border-collapse: collapse;
      margin: 10px 0;
      font-size: 12px;
      color: #666;
      border: 1px solid #ddd;
    }

    th,
    td {
      padding: 10px;
      border: 1px solid #ddd;
    }
    th {
      background-color: #f2f2f2;
    }
    thead {
      th {
        background-color: #c1c1c1;
        font-weight: bold;
      }
    }
  }
}

.banner {
  img {
    width: 100%;
  }
}
h3 {
  font-size: 3.5vw;
  margin: 0;
}
p {
  font-size: 2vw;
  margin: 0;
}
.part-a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5vw 6vw;
  flex-wrap: wrap;
  width: 100vw;
  box-sizing: border-box;
  background: #fff;
  .item {
    width: 42vw;
    display: flex;
    height: 24vw;
    justify-content: center;
    align-items: center;
    border-radius: .8vw;
    margin-bottom: 3vw;
    h3 {
      color: #fff;
    }
    p {
      color: #fff;
      margin-top: 1vw;
    }
    img {
      width: 10vw;
      margin-right: 2.7vw;
    }
    &.item1 {
      background: #E5287E;
    }
    &.item2 {
      background: #02A1EA;
    }
    &.item3 {
      background: #ED6D00;
    }
    &.item4 {
      background: #90C222;
    }
  }
}
.part-b {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  width: 100%;
  padding: 0 0 5vw;
  background: #fff;
  .item {
    display: block;
    width: 25%;
    // margin: 0 5vw;
    margin-top: 10px;
    text-align: center;
    height: auto;
    img {
      width: 9.5vw;
    }
    h3 {
      text-align: center;
      color: #000;
    }
  }
}
.part-c {
  background: #F2F2F2;
  padding: 5vw 6vw;
  .wrap {
    h3 {
      text-align: center;
      font-size: 4vw;
      margin-bottom: 3vw;
    }
  }
  p {
    color: #000;
    line-height: 1.8;
    margin-bottom: 4vw;
    font-size: 2.4vw;
    em {
      font-size: 3.2vw;
      font-style: normal;
    }
  }
}
.part-d {
  background: #fff;
  padding: 5vw 0;
  h2 {
    text-align: center;
    font-size: 4vw;
    margin: 0;
  }
  .list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 5vw;
    .item {
      width: 40vw;
      margin: 0 4vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 5vw;
      img {
        width: 13.5vw;
      }
      span {
        font-size: 3.2vw;
        color: #000;
        margin: 1.5vw 0;
      }
      p {
        font-size: 2.4vw;
        color: #6E6E6E;
      }
    }
  }
}

</style>
